import React from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import './Navbar.css'; // Assurez-vous que le chemin est correct

const MyNavbar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Mon App</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Insérez des liens Nav.Link ici si nécessaire */}
          </Nav>
          <Button variant="outline-success">
            <PersonCircle size={24} /> Connexion / Inscription
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
