import React from 'react';
import './App.css';
import MyNavbar from './components/Navbar'; // Assurez-vous que le chemin d'importation est correct

function App() {
  return (
    <MyNavbar />

  );
}

export default App;
